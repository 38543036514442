import { Box, Container } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import IntroHead from '#/components/shared/IntroHead';

type DashboardContainerProps = {
  introTitle?: string;
  introDescription?: string;
  children: ReactNode;
  sx?: SxProps;
};

export default function DashboardContainer({
  introTitle,
  introDescription,
  children,
  sx,
}: DashboardContainerProps) {
  return (
    <Container maxWidth="lg">
      {introTitle && (
        <IntroHead title={introTitle} description={introDescription || ''} />
      )}
      <Box sx={sx}>{children}</Box>
    </Container>
  );
}
